import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mx-5",attrs:{"id":"AdminUsers"}},[_c(VContainer,{staticClass:"mt-10",attrs:{"fluid":""}},[_c(VRow,[_c('h1',{staticClass:"mb-5"},[_vm._v("Alta de empleado")]),_c(VCard,{staticClass:"pa-10",attrs:{"width":"100%"}},[_c(VRow,[_c(VCol,{attrs:{"cols":"12","lg":"4"}},[_c(VTextField,{attrs:{"label":"Nombre","outlined":""}})],1),_c(VCol,{attrs:{"cols":"12","lg":"4"}},[_c(VTextField,{attrs:{"label":"Apellidos","outlined":""}})],1),_c(VCol,{attrs:{"cols":"12","lg":"4"}},[_c(VTextField,{attrs:{"label":"Correo","outlined":""}})],1),_c(VCol,{attrs:{"cols":"12","lg":"4"}},[_c(VTextField,{attrs:{"label":"Telefono","outlined":""}})],1),_c(VCol,{attrs:{"cols":"12","lg":"4"}},[_c(VTextField,{attrs:{"label":"Role","outlined":""}})],1),_c(VCol,{attrs:{"cols":"12","lg":"4"}},[_c(VTextField,{attrs:{"label":"Password","outlined":""}})],1)],1)],1)],1)],1),_c(VContainer,{staticClass:"mt-10",attrs:{"fluid":""}},[_c('h1',{staticClass:"mb-5"},[_vm._v("Usuarios registrados")]),_c(VCard,{attrs:{"width":"100%"}},[_c(VCardTitle,[_c(VSpacer),_c(VTextField,{attrs:{"append-icon":"mdi-magnify","label":"Buscar","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.desserts,"search":_vm.search}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }