import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mx-5",attrs:{"id":"AdminReports"}},[_c(VContainer,{staticClass:"mt-10",attrs:{"fluid":""}},[_c(VContainer,{staticClass:"mt-10",attrs:{"fluid":""}},[_c('h1',{staticClass:"mb-5"},[_vm._v("Resumen completo")]),_c(VCard,{attrs:{"width":"100%"}},[_c('div',{staticClass:"d-none"},[_vm._l((_vm.transaction_list),function(transaction){return _c('h1',{staticClass:"d-none"},[_vm._v(_vm._s(transaction.service_id))])}),_c('h1',[_vm._v(_vm._s(_vm.transaction_list))])],2),_c(VCardTitle,[_c(VSpacer),_c(VTextField,{attrs:{"append-icon":"mdi-magnify","label":"Buscar","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),(_vm.transaction_list)?_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.transaction_list,"search":_vm.search}}):_vm._e()],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }