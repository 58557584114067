<template>
  <div id="Footer">
    <!-- <v-footer app class="py-10" color="#2cb1d2">
      <v-row class="justify-start align-center">
        <h6 class="mr-5 txt-white">Powered by:</h6>
        <v-img max-width="150" src="@/assets/img/metahub-logo.png"></v-img>
      </v-row>
      </v-footer> -->
  </div>
</template>

<style lang="scss">
    @import "@/assets/scss/components/Footer.scss";
</style>

<script>
export default {
  name: "Footer",

  data: () => ({
    //
  }),
};
</script>
