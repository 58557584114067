import { VApp } from 'vuetify/lib/components/VApp';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VImg } from 'vuetify/lib/components/VImg';
import { VMain } from 'vuetify/lib/components/VMain';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VApp,[_c(VMain,[_c('Loading'),(_vm.login == false)?_c(VContainer,{attrs:{"fill-height":""}},[_c(VRow,{staticClass:"justify-center"},[(_vm.loginanimation == false)?_c(VCard,{staticClass:"text-center pa-10",attrs:{"width":"500px"}},[_c(VImg,{staticClass:"mx-auto",attrs:{"max-width":"300","src":require("@/assets/img/logo.png")}}),_c('h4',{staticClass:"my-5"},[_vm._v("Introduce tus credenciales")]),_c(VForm,{model:{value:(_vm.loginform),callback:function ($$v) {_vm.loginform=$$v},expression:"loginform"}},[_c(VContainer,[_c(VTextField,{attrs:{"type":"email","label":"Correo","outlined":""}}),_c(VTextField,{attrs:{"type":"password","label":"Contraseña","outlined":""}}),_c(VBtn,{attrs:{"depressed":"","color":"primary"},on:{"click":function($event){return _vm.loginUser()}}},[_vm._v(" Iniciar sesión ")])],1)],1)],1):_vm._e(),(_vm.loginanimation == true)?_c(VCard,{staticClass:"text-center pa-10",attrs:{"width":"500px"}},[_c(VProgressLinear,{attrs:{"color":"deep-purple accent-4","indeterminate":"","rounded":"","height":"6"}})],1):_vm._e()],1)],1):_vm._e(),(_vm.login == true)?_c('Menu'):_vm._e(),(_vm.login == true)?_c('router-view'):_vm._e(),(_vm.login == true)?_c('Footer'):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }