import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAppBarNavIcon } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"Menu"}},[_c(VNavigationDrawer,{attrs:{"app":"","color":"#e58abe"},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('div',{staticClass:"pa-2"},[_c(VBtn,{staticClass:"txt-white bold",attrs:{"block":"","color":"#2cb1d2"}},[_vm._v(" Cerrar sesión ")])],1)]},proxy:true}]),model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c(VListItem,{staticClass:"mt-5"},[_c(VListItemContent,{staticClass:"circle-logo rounded-circle"},[_c(VImg,{attrs:{"max-width":"100%","src":require("@/assets/img/logo.png")}})],1)],1),_c(VList,{staticClass:"mt-10"},_vm._l((_vm.items),function(item){return _c(VListItem,{key:item.title,attrs:{"link":"","to":item.url}},[_c(VListItemIcon,[_c(VIcon,{attrs:{"color":"#FFFFFF"}},[_vm._v(_vm._s(item.icon))])],1),_c(VListItemContent,[_c(VListItemTitle,[_c('span',{staticClass:"bold txt-white"},[_vm._v(_vm._s(item.title))])])],1)],1)}),1)],1),_c(VAppBar,{attrs:{"app":"","elevation":"0","color":"#2cb1d2"}},[_c(VAppBarNavIcon,{attrs:{"color":"#FFFFFF"},on:{"click":function($event){_vm.drawer = !_vm.drawer}}}),_c(VSpacer),_c(VToolbarTitle,[_c('span',{staticClass:"d-block texto txt-white"},[_vm._v("Bienvenido: Admin")])]),_c(VToolbarTitle,[_c('span',{staticClass:"d-block texto txt-white"},[_vm._v("ID: 123456")])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }