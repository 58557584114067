<template>
    <div id="AdminSettings">
        
    </div>
</template>

<script>

    export default {
        name: "AdminSettings",

        components : {
            
        },

        data: () => ({
            //
        }),
    };
</script>